import { AfterContentInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import dayJs from 'dayjs';
import { CalendarEvent, CalendarEventStart } from '../../../models/calendar.model';
import { Event } from '../../../dashboard/store/calendar/interfaces/event.interface';
import { EventDate } from '../../../dashboard/store/calendar/interfaces/event-date.interface';

@Component({
  selector: 'app-time-select',
  templateUrl: './time-select.component.html',
  styleUrls: ['./time-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TimeSelectComponent,
      multi: true,
    },
  ],
})
export class TimeSelectComponent implements ControlValueAccessor, AfterContentInit {
  @Input() public step = 15;
  @Input() public startTime = '00:00 am';
  @Input() public endTime = '00:00 am';

  @Input() handInput = false;

  @Input() public borderColor = '#CFD0DE';
  @Input() placeholderValue = 'Time';
  @Input() valueTime = '';
  options = {
    now: new Date(),
    hoursEnd: null,
    minutes: null,
    count: 0,
    countEnd: 0,
    isToday: null,
    isEnd: null,
    isStart: null,
    startDate: null,
    isEdit: null,
    isMinTime: null,
    isMaxTime: null,
    maxTime: null,
    minTime: null,
    isActive: true,
    overnight: false,
    prevPeriodOvernight: false,
    idSlot: '',
  };
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  configScroll = {
    wheelPropagation: true,
    autoPropagation: true,
  };
  public timeSlots: Array<{ name: string; disable: boolean }> = [];
  public isOpen: boolean = false;
  val: string = '';
  regex = new RegExp('^(0?[1-9]|1[0-2]):[0-5][0-9](AM|PM)$');

  private _minTime = '';

  get minTime(): string {
    return this._minTime;
  }

  @Input() set minTime(value: string) {
    this._minTime = value ? value : this.startTime;
    this.getAvailableSlot();
  }

  private _maxTime = '';

  get maxTime(): string {
    return this._maxTime;
  }

  @Input() set maxTime(value: string) {
    this._maxTime = value ? value : this.endTime;
    this.getAvailableSlot();
  }

  get minTimeSlot(): string {
    return this.minTime && dayJs(this._minTime, 'hh:mm a').isValid()
      ? dayJs(this._minTime, 'hh:mm a').add(1, 'minutes').format('hh:mm A')
      : '12:00 AM';
  }

  get maxTimeSlot(): string {
    return dayJs(this._maxTime, 'hh:mm a').isValid() ? dayJs(this._maxTime, 'hh:mm a').add(1, 'minutes').format('hh:mm A') : '11:59 PM';
  }

  private _overnight = false;

  get overnight(): boolean {
    return this._overnight;
  }

  @Input() set overnight(value: boolean) {
    this._overnight = value;
    this.getAvailableSlot();
  }

  private _previousDayOvernight: string;

  get previousDayOvernight(): string {
    return this._previousDayOvernight;
  }

  @Input() set previousDayOvernight(value: string) {
    this._previousDayOvernight = value;
    this.minTime = value;
  }

  @Input() set isMinTime(value: boolean) {
    if (value) {
      this.options.isMinTime = true;
    }
  }

  @Input() set isMaxTime(value: boolean) {
    if (value) {
      this.options.isMaxTime = true;
    }
  }

  @Input() set isStart(value: boolean) {
    if (value) {
      this.options.isStart = true;
    }
  }

  @Input() set isEnd(value: boolean) {
    if (value) {
      this.options.isEnd = true;
    }
  }

  @Input() set isToday(value: boolean) {
    if (value) {
      this.options.isToday = true;
    }
  }

  @Input() set isEdit(value: boolean) {
    if (value) {
      this.options.isEdit = true;
    }
  }

  @Input() set data(value: any) {
    if (value) {
      value = dayJs(value.date).format();
      this.options.startDate = value.datetime;
      this.getTimeRemaining();
      this.generateTimeSlots();
    }
  }

  @Input() set event(value: CalendarEvent | Event) {
    if (value) {
      if (value.start) {
        this.getTimeRemaining();
        this.checkDateIsToday(value.start);
      }
    }
  }

  get value(): string {
    return this.val;
  }

  set value(val: string) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  ngAfterContentInit(): void {
    if ((this.options.isToday && this.options.isStart) || (this.options.isEdit && this.options.isStart)) {
      this.getTimeRemaining();
    }
    this.generateTimeSlots();
  }

  public generateTimeSlots(): void {
    let startTime = dayJs(this.startTime, 'hh:mm a');
    const endTime = dayJs(this.endTime, 'hh:mm a').add(1, 'day');
    this.timeSlots = [];
    while (startTime <= endTime) {
      const slot = dayJs(startTime, 'hh:mm a').add(startTime.toISOString() === endTime.toISOString() ? -1 : 0, 'minutes');
      this.timeSlots.push({ name: slot.format('hh:mm a').replace('00:', '12:'), disable: false });
      startTime = startTime.add(this.step, 'minutes');
    }

    this.getAvailableSlot();
  }

  public getAvailableSlot(): void {
    if (this.options.isStart && !this.previousDayOvernight) {
      return;
    }
    this.timeSlots.forEach(timeSlot => {
      const startTime = dayJs(this.minTime, 'hh:mm a');
      let endTime = dayJs(this.minTime, 'hh:mm a');
      let current = dayJs(timeSlot.name, 'hh:mm a');
      if (endTime.isBefore(startTime) || startTime.valueOf() === endTime.valueOf()) {
        endTime = endTime.add(1, 'day');
      }
      if (this.overnight) {
        current = current.add(1, 'day');
        endTime = endTime.add(1, 'day');
      }
      const timeTo = !(startTime.isBefore(current) && endTime.isAfter(current));
      const timeFrom = !((startTime.isBefore(current) && endTime.isAfter(current)) || startTime.valueOf() === current.valueOf());
      timeSlot.disable = this.previousDayOvernight ? timeFrom : timeTo;
    });
  }

  onTouchmove($event: TouchEvent): void {
    $event.stopPropagation();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = (): void => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = (): void => {};

  writeValue(value: any): void {
    if (value) {
      this.value = value.replace('00:', '12:');
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  private getTimeRemaining(): void {
    this.options.minutes = this.options.now.getMinutes();
    this.options.hoursEnd = this.options.now.getHours();
    this.options.hoursEnd = this.options.minutes > 30 ? this.options.hoursEnd + 1 : this.options.hoursEnd;
  }

  private checkDateIsToday(value: CalendarEventStart | EventDate): void {
    if (value) {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      const date: string = value.toString();
      if (new Date().setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0)) {
        this.options.isToday = true;
      } else {
        this.options.isToday = false;
        this.generateTimeSlots();
      }
    }
  }
}
